import React, { Suspense } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useCallback, useEffect } from "react";
import AppContextProvider from "./context";
import {
  getRefreshToken,
  saveToStorage,
  getAccessToken,
} from "src/helper/storage";
import {
  updateJWT,
  updateStorageStatus,
} from "src/graphql/state/auth/authActions";
import { REFRESH_TOKEN } from "src/graphql/queries/auth.queries";
import { useLazyQuery } from "@apollo/client";
import NotFound from "./pages/not-found";
import FallbackComponent from "./components/Fallback";
import Invitations from "./pages/invitations";
import Rsvp from "./pages/rsvp";
import Website from "./pages/website";
import Gifts from "./pages/gifts";
import Wallet from "./pages/wallet";
import AcceptTeamInvite from "./pages/accept-team-invite";
import EventDetails from "./pages/event-details";
import EventVendors from "./pages/event-vendors";
import EventSchedule from "./pages/event-schedule";
import MyPlan from "./pages/my-plan";
import Clear from "./pages/clear";
import LiveHall from "./pages/live-hall";
import jwt_decode, { JwtPayload } from "jwt-decode";
import toast from "react-hot-toast";
import mixpanel from "mixpanel-browser";
import Photobook from "./pages/photobook";
import Guests from "./pages/guests";
import { GoogleOAuthProvider } from "@react-oauth/google";
// import AccessControl from "./pages/access-control";

if (!!process.env.REACT_APP_MIXPANEL) {
  mixpanel.init(process.env.REACT_APP_MIXPANEL, {
    debug: process.env.NODE_ENV === "development" ? true : false,
  });
  // mixpanel.track("Tracking after mixpanel.init");
}

//code splitting
const Register = React.lazy(() => import("./pages/register"));
const GeneralInvite = React.lazy(() => import("./pages/general-invite"));
const Login = React.lazy(() => import("./pages/login"));
const ForgotPassword = React.lazy(() => import("./pages/forgot-password"));
const Events = React.lazy(() => import("./pages/events"));
const Profile = React.lazy(() => import("./pages/profile"));

function App() {
  let location = useLocation();
  const navigate = useNavigate();
  const [refreshTokenData] = useLazyQuery(REFRESH_TOKEN, {
    variables: {
      refresh_token: getRefreshToken(),
    },
    onCompleted(data) {
      startSilentRefresh(true);
      updateJWT(data?.refresh);
      if (
        data?.refresh.refresh_token &&
        data?.refresh.access_token &&
        data?.refresh.access_expiry
      ) {
        const { refresh_token, access_token, access_expiry } = data?.refresh;
        const jwt = { refresh_token, access_token, access_expiry };
        saveToStorage("inawo", jwt);
      }
    },
    onError({ message }) {
      if (message === "unauthenticated") {
        toast.error(<p className="toast">Your session has expired.</p>);
        updateStorageStatus(false);
        navigate("/login");
      } else {
        navigate("/clear");
      }
    },
  });

  const startSilentRefresh = useCallback(
    (loggedIn: boolean) => {
      let isMounted = false;
      if (!isMounted) {
        if (loggedIn) {
          //start countdown to silent refresh after login/signup
          //let getExpiry = expiryTime();
          let accessTokenStr = getAccessToken();

          if (jwt_decode<JwtPayload>(accessTokenStr)?.exp) {
            let expiry = jwt_decode<JwtPayload>(accessTokenStr)?.exp;
            if (expiry) {
              //if (expiry * 1000 > Date.now() || getExpiry) {
              if (expiry * 1000 > Date.now()) {
                let time = expiry * 1000 - Date.now();

                if (time <= 5000) {
                  refreshTokenData();
                }
              }
              setTimeout(() => {
                refreshTokenData();
              }, expiry - 5000);
            }
          }
        }
      }
      return () => {
        isMounted = false;
      };
    },
    [refreshTokenData]
  );

  //store silent refresh function in state
  //refreshAction(startSilentRefresh);

  useEffect(() => {
    //run silent refresh after page load if jwt isn't in memory
    let isMounted = false;
    if (!isMounted) {
      let userLoggedIn = localStorage["loggedIn"];
      if (userLoggedIn) userLoggedIn = JSON.parse(userLoggedIn);

      if (userLoggedIn) {
        //start countdown to silent refresh after login/signup
        //let getExpiry = expiryTime();
        let accessTokenStr = getAccessToken();

        if (jwt_decode<JwtPayload>(accessTokenStr)?.exp) {
          let expiry = jwt_decode<JwtPayload>(accessTokenStr)?.exp;

          if (expiry) {
            //if (expiry * 1000 >= Date.now() || getExpiry) {
            if (expiry * 1000 >= Date.now()) {
              let time = expiry * 1000 - Date.now();
              if (time <= 5000) {
                refreshTokenData();
              }
            }
          }
        }
      }
    }
    return () => {
      isMounted = false;
    };
  }, [location.pathname, refreshTokenData]);

  return (
    <div className="app">
      <GoogleOAuthProvider
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}
      >
        <AppContextProvider>
          <Suspense fallback={<FallbackComponent />}>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/clear" element={<Clear />} />
              <Route path={`/general-invite`} element={<GeneralInvite />} />
              <Route path="/register" element={<Register />} />
              <Route path="/login" element={<Login />} />
              <Route path="/dashboard" element={<MyPlan />} />
              <Route path="/my-plan" element={<MyPlan />} />
              <Route path="/invitations" element={<Invitations />} />
              <Route path="/event-details" element={<EventDetails />} />
              <Route path="/event-schedule" element={<EventSchedule />} />
              {/* <Route path="/access-control" element={<AccessControl />} /> */}
              <Route path="/event-vendors" element={<EventVendors />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/gifts" element={<Gifts />} />
              <Route path="/wallet" element={<Wallet />} />
              <Route path="/website" element={<Website />} />
              <Route path="/rsvp" element={<Rsvp />} />
              <Route path="/events" element={<Events />} />
              <Route path="/accept-team" element={<AcceptTeamInvite />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/live-hall" element={<LiveHall />} />
              <Route path="*" element={<NotFound />} />
              {/* new routes from decoupling */}
              <Route path="/guests" element={<Guests />} />
              <Route path="/photobook" element={<Photobook />} />
            </Routes>
          </Suspense>
        </AppContextProvider>
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;
